/**
* Generated automatically at built-time (2024-10-11T12:34:43.036Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "37fac89f-fca6-43d7-b3ca-548d53f85b90",templateKey: "sites/104-368e1239-6155-4b22-bbc2-3c530950892d"};