/**
* Generated automatically at built-time (2024-10-11T12:34:42.397Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "collections-de-cuisines-exterieures",templateKey: "sites/104-5d417f7f-620e-4d5e-b2c0-dd09d3c4462e"};